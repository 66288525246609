import {
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';

type MeasurementsInputProps = {
    id: string;
    label: string;
    subLabel?: string;
    unit: string;
    measurementValue: number;
    disabled?: boolean;
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const MeasurementsInput = ({
    id,
    label,
    subLabel,
    unit,
    measurementValue,
    disabled,
    handleChange,
}: MeasurementsInputProps) => {
    const [value, setValue] = useState(measurementValue);
    const handleValueChange = (event: any) => {
        setValue(event.target.value);
        if (!Number.isNaN(event.target.value)) {
            handleChange(event);
        }
    };

    useEffect(() => {
        setValue(value);
    }, [value]);
    return (
        <FormControl fullWidth variant='outlined'>
            <InputLabel sx={{ lineHeight: '3.3rem' }} htmlFor={label}>
                {label}
            </InputLabel>
            <OutlinedInput
                id={id}
                key={id}
                value={value}
                margin='dense'
                inputProps={{ style: { height: '2.55rem' } }}
                endAdornment={
                    <InputAdornment position='end'>
                        <span>{unit}</span>
                    </InputAdornment>
                }
                label={label}
                disabled={disabled}
                onChange={handleValueChange}
            />
            {subLabel && (
                <FormHelperText sx={{ marginTop: '-20px' }}>
                    {subLabel}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default MeasurementsInput;
