/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { ArrowDropDown, LockOutlined } from '@material-ui/icons';
import UserRoles from '../../lib/UserRoles';
import {
    projectMenus,
    projectMenusVisible,
    projectRoutes,
} from '../../lib/ProjectMenu';
import { projectName } from '../../lib/ProjectFormatter';
import { formatFilename, getFilename } from '../../lib/UrlParser';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';

class ProjectBreadcrumb extends Component {
    constructor(props) {
        super(props);

        this.state = {
            crumbMenuOpen: false,
        };
    }

    getMenu() {
        const currentUserRoles = [];
        if (this.props.estimatesDisabled) {
            currentUserRoles.push(UserRoles.EstimatesDisabled);
        }
        const visibleMenus = projectMenusVisible.filter(
            (item) =>
                !(
                    item.hideForRoles &&
                    item.hideForRoles.some((r) => currentUserRoles.includes(r))
                )
        );
        // this first MenuItem is to stop the first item from being selected via tab index for keyboards and never going away during hovers...
        return [
            <MenuItem
                key='hiddenTabIndex'
                style={{ height: 0, padding: 0, margin: 0 }}
            />,
        ].concat(visibleMenus.map((menu) => this.crumbMenuItem(menu)));
    }

    projectPath = () =>
        `${this.props.match.path}/${this.props.currentProject.id}`;

    handleClickProjects = () => {
        this.props.history.push(this.props.match.path);
    };

    projectsCrumb = () => (
        <li>
            <Button color='primary' onClick={this.handleClickProjects}>
                <div>
                    <span>Projects</span>
                </div>
            </Button>
        </li>
    );

    handleClickProject = () => {
        this.props.history.push(this.projectPath());
    };

    projectCrumb = () => (
        <li>
            <Button color='primary' onClick={this.handleClickProject}>
                <div>
                    <span>{projectName(this.props.currentProject)}</span>
                </div>
            </Button>
        </li>
    );

    handleCrumbMenuClick = (event) => {
        // This prevents ghost click.
        event.preventDefault();

        this.setState({
            crumbMenuOpen: true,
            anchorElement: event.currentTarget,
        });
    };

    handleCrumbMenuClose = () => {
        this.setState({
            crumbMenuOpen: false,
        });
    };

    handleCrumbMenuCloseAndClick = (clickPath) => () => {
        this.handleCrumbMenuClose();
        this.props.history.push(clickPath);
    };

    projectCrumbMenu = ({ match }) => (
        <li>
            <Button
                color='primary'
                id='projectCrumbMenu'
                onClick={this.handleCrumbMenuClick}
            >
                <div>
                    <span>
                        {
                            projectMenus.find(
                                (menu) => menu.Route === match.params.area
                            ).Title
                        }
                    </span>
                    <ArrowDropDown
                        style={{
                            verticalAlign: 'middle',
                            fill: 'rgb(0, 92, 170)',
                        }}
                    />
                </div>
            </Button>
            <Menu
                open={this.state.crumbMenuOpen}
                anchorEl={this.state.anchorElement}
                onClose={this.handleCrumbMenuClose}
            >
                {this.getMenu()}
            </Menu>
        </li>
    );

    handleLockedClicked = () => {
        this.handleCrumbMenuClose();
        this.props.dispatch(
            setReduxMessage(
                'Subscribe for access to estimates and exclusive order discounts.',
                <Button
                    color='inherit'
                    onClick={() => this.props.history.push('/settings/billing')}
                >
                    Subscribe
                </Button>
            )
        );
    };

    crumbMenuItem = (menu) => {
        const clickPath = `${this.projectPath()}/${menu.Route}`;
        const isLocked =
            menu.isSubscriberOnly && this.props.subscriptionExpired;
        const menuId = `crumbMenuItem${menu.Title}`;
        return (
            <div
                role='presentation'
                onClick={isLocked ? this.handleLockedClicked : null}
            >
                <MenuItem
                    key={menu.Title}
                    id={
                        menu.Title !== '3D Viewer (Beta)'
                            ? menuId
                            : 'crumbMenuItemViewer3d'
                    }
                    onClick={this.handleCrumbMenuCloseAndClick(clickPath)}
                    selected={false}
                    disabled={isLocked}
                >
                    <ListItemIcon>{menu.Icon}</ListItemIcon>
                    <ListItemText primary={menu.Title} />
                    {isLocked && <LockOutlined />}
                </MenuItem>
            </div>
        );
    };

    estimateCrumb = () => (
        <li>
            <Button disabled>
                <div>
                    <span>{this.props.currentEstimate.name}</span>
                </div>
            </Button>
        </li>
    );

    imageCrumb = () => (
        <li>
            <Button disabled>
                {formatFilename(getFilename(this.props.currentImage.imageUrl))}
            </Button>
        </li>
    );

    createESignatureCrumb = () => (
        <li>
            <Button disabled>
                <div>
                    <span>Create eSignature</span>
                </div>
            </Button>
        </li>
    );

    deletedProjectsCrumb = () => (
        <li>
            <Button disabled>
                <div>
                    <span>Deleted</span>
                </div>
            </Button>
        </li>
    );

    render() {
        const { path } = this.props.match;

        return [
            <Route key='0' path={`${path}`} component={this.projectsCrumb} />,
            <Route
                key='1'
                path={`${path}/:id`}
                component={
                    this.props.location.pathname.endsWith('/deleted')
                        ? this.deletedProjectsCrumb
                        : this.projectCrumb
                }
            />,
            <Route
                key='2'
                path={`${path}/:id/:area`}
                component={this.projectCrumbMenu}
            />,
            this.props.location.pathname.endsWith(
                `${projectRoutes.ESignatures}/create`
            ) && (
                <Route
                    key='3'
                    path={`${path}/:id/${projectRoutes.ESignatures}/create`}
                    component={this.createESignatureCrumb}
                />
            ),
            this.props.currentEstimate.name && (
                <Route
                    key='3'
                    path={`${path}/:id/${projectRoutes.Estimates}/:estimateId`}
                    component={this.estimateCrumb}
                />
            ),
            this.props.currentImage.imageUrl && (
                <Route
                    key='4'
                    path={`${path}/:id/${projectRoutes.Images}/:imageId`}
                    component={this.imageCrumb}
                />
            ),
        ];
    }
}

ProjectBreadcrumb.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({ id: PropTypes.string }),
    }).isRequired,
    currentProject: PropTypes.shape({
        id: PropTypes.string,
        projectName: PropTypes.string,
    }),
    currentEstimate: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
    }),
    currentImage: PropTypes.shape({
        id: PropTypes.string,
        imageUrl: PropTypes.string,
    }),
};

ProjectBreadcrumb.defaultProps = {
    currentProject: {},
    currentEstimate: {},
    currentImage: {},
};

const mapStateToProps = (state) => {
    const {
        projectHome,
        projectEstimate,
        projectImages,
        organization,
        currentUserRoles,
    } = state;
    const { subscriptionExpired } = organization;
    const { currentProject } = projectHome;
    const { estimate: currentEstimate } = projectEstimate;
    const { selectedImage: currentImage } = projectImages;
    const { estimatesDisabled } = currentUserRoles;
    return {
        currentProject,
        currentEstimate,
        currentImage,
        subscriptionExpired,
        estimatesDisabled,
    };
};

export default withRouter(connect(mapStateToProps)(ProjectBreadcrumb));
