import LocalStorageWrapper from '../lib/LocalStorageWrapper';
import LoggerWrapper from '../lib/Logger';
import httpRequestMethods from '../middleware/httpRequestMethods';
import { httpMethod } from '../middleware/api';
import { getCurrentUser } from './User';
import { getOrganization } from './Organization';
import { getOrganizationFeatures } from './OrganizationFeatures';
import { getSketchOrderPricing } from './SketchOrderPricingActions';
import { selectUser } from './Projects';
import { getCurrentUserRoles } from './UserRoles';
import decode from '../lib/decodeRoofSnapAuthToken';
import heap from '../lib/Heap.ts';
import buildProfile from '../lib/BuildProfile.ts';

const chameleon = require('@chamaeleonidae/chmln');

// Login Actions
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGIN_REQUEST_BACKGROUND = 'LOGIN_REQUEST_BACKGROUND';

// Three possible states for our logout process as well.
// Since we are using JWTs, we just need to remove the token
// from localStorage. These actions are more useful if we
// were calling the API to log the userId out
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

const requestLogin = (requestInBackground = false) => ({
    type: requestInBackground ? LOGIN_REQUEST_BACKGROUND : LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
});

const receiveLogin = (accessToken, user) => ({
    type: LOGIN_SUCCESS,
    accessToken,
    isFetching: false,
    isAuthenticated: true,
    user,
});

const rejectLogin = (message) => ({
    type: LOGIN_FAILURE,
    isFetching: false,
    message,
});

function requestLogout() {
    return {
        type: LOGOUT_REQUEST,
        isFetching: true,
        isAuthenticated: true,
    };
}

function receiveLogout() {
    return {
        type: LOGOUT_SUCCESS,
        isFetching: false,
        isAuthenticated: false,
        user: {},
    };
}

const authenticate = async (
    dispatch,
    username,
    password,
    requestInBackground = false
) => {
    dispatch(requestLogin(requestInBackground));
    const config = {
        method: httpRequestMethods.POST,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `username=${encodeURIComponent(
            username
        )}&password=${encodeURIComponent(
            password
        )}&grant_type=password&client_id=1af9339e-2f1f-4963-8c00-c7093471b48f`,
    };

    try {
        // TODO: transition to new roofsnap auth server
        const response = await fetch(
            `https://${process.env.REACT_APP_AUTH_HOST}/oauth2/token`,
            config
        );
        const json = await response.json();

        if (!response.ok) {
            return Promise.reject(json.error_description);
        }

        // Save jwt to local storage
        LocalStorageWrapper.setItem('accessToken', json.access_token);

        // Decode the token payload and save values to local storage
        const tokenPayload = decode(json.access_token);
        const { userId } = tokenPayload;

        // Log in to www.roofsnap.com
        try {
            await fetch(
                `${process.env.REACT_APP_ROOFSNAP_ONLINE_HOST}/Account/LoginToken?token=${json.access_token}`,
                {
                    method: httpMethod.GET,
                    credentials: 'include',
                    mode: 'no-cors',
                }
            );
        } catch (error) {
            LoggerWrapper.captureException(error);
        }

        return {
            accessToken: json.access_token,
            userId,
        };
    } catch (error) {
        return Promise.reject(error);
    }
};

const buildChameleonProfile = (user, org, orgFeatures) => ({
    ...buildProfile(user, org, orgFeatures, true),
    userRoleIds: JSON.stringify(user.userRoleIds),
});

const buildAppCuesProfile = (user, org, orgFeatures) => ({
    ...buildProfile(user, org, orgFeatures),
    userRoleIds: user.userRoleIds,
});

const buildHeapProfile = (user, org, orgFeatures) => ({
    ...buildProfile(user, org, orgFeatures),
    userRoleIds: JSON.stringify(user.userRoleIds),
});

const reportToAnalytics = (user, org, orgFeatures) => {
    try {
        const appCuesProfile = buildAppCuesProfile(user, org, orgFeatures);
        window.Appcues.identify(user.userId, appCuesProfile);
        reportToHeap(user, org, orgFeatures);
        reportToChameleon(user, org, orgFeatures);
    } catch (error) {
        LoggerWrapper.captureException(error);
    }
};

const reportToHeap = (user, org, orgFeatures) => {
    const heapProfile = buildHeapProfile(user, org, orgFeatures);
    if (!heap) return;
    heap.identify(user.userName);
    heap.addUserProperties(heapProfile);
};

const reportToChameleon = (user, org, orgFeatures) => {
    const chameleonProfile = buildChameleonProfile(user, org, orgFeatures);

    chameleon.init(process.env.REACT_APP_CHAMELEON_TRACKING_ID, {
        fastUrl: 'https://fast.chameleon.io/',
    });
    chameleon.identify(user.userName, chameleonProfile);
};

export const login = (username, password, requestInBackground = false) => {
    LocalStorageWrapper.clear();

    return async (dispatch) => {
        try {
            const { accessToken, userId } = await authenticate(
                dispatch,
                username,
                password,
                requestInBackground
            );

            try {
                const results = await Promise.all([
                    dispatch(getCurrentUser(requestInBackground)),
                    dispatch(getCurrentUserRoles(requestInBackground)),
                    dispatch(getOrganization(requestInBackground)),
                    dispatch(getOrganizationFeatures(requestInBackground)),
                    dispatch(getSketchOrderPricing(requestInBackground)),
                ]);
                // Set the default project user filter to the user who has logged in
                dispatch(selectUser(userId));

                const getCurrentUserResult = results[0];
                const getCurrentOrgResult = results[2];
                const getCurrentOrgFeaturesResult = results[3];

                const { response: user } = getCurrentUserResult;
                const { response: org } = getCurrentOrgResult;
                const { response: orgFeatures } = getCurrentOrgFeaturesResult;

                reportToAnalytics(user, org, orgFeatures);
                return dispatch(receiveLogin(accessToken, user));
            } catch (error) {
                return Promise.reject(error);
            }
        } catch (error) {
            return dispatch(rejectLogin('Username or password is incorrect'));
        }
    };
};

// Logs the userId out
export const logoutUser = () => {
    // Logout of RoofSnap.com
    fetch(`${process.env.REACT_APP_ROOFSNAP_ONLINE_HOST}/Account/LoginToken`, {
        credentials: 'include',
        method: httpMethod.GET,
        mode: 'no-cors',
    }).catch((err) => LoggerWrapper.captureException(err));

    return (dispatch) => {
        dispatch(requestLogout());
        LocalStorageWrapper.clear();
        return dispatch(receiveLogout());
    };
};
