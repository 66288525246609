import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Button, ButtonGroup, Stack } from '@mui/material';
import { Measurements } from 'lib/Models/ProjectDrawing';

const FormSections = [
    'squares-section',
    'lines-section',
    'category-section',
    'pitch-section',
] as const;

export type FormSection = (typeof FormSections)[number];

export type MeasurementFormField = keyof Measurements;

export type MeasurementFormNavigationProps = {
    focusField?: MeasurementFormField;
};

const MeasurementFormNavigation = ({
    focusField,
}: MeasurementFormNavigationProps) => {
    const [focusedSection, setFocusedSection] =
        useState<FormSection>('squares-section');

    useEffect(() => {
        if (!focusField) return;

        const focusFieldElem = document.querySelector(
            `[name="${focusField}"]`
        ) as HTMLInputElement;

        if (focusFieldElem) {
            focusFieldElem.scrollIntoView();
            focusFieldElem.focus();
        }
    }, [focusField]);

    const focusedSectionElem = document.querySelector(`#${focusedSection}`);

    if (focusedSectionElem) focusedSectionElem.scrollIntoView();

    const NavButton = ({
        children,
        section,
    }: PropsWithChildren<{ section: FormSection }>) => {
        const handleClick = () => {
            setFocusedSection(section);
        };

        return (
            <Button
                sx={{ textTransform: 'none' }}
                color={focusedSection === section ? 'primary' : 'inherit'}
                onClick={handleClick}
            >
                {children}
            </Button>
        );
    };

    return (
        <Stack sx={{ m: '1rem 0' }} alignItems='center'>
            <ButtonGroup color='inherit' variant='outlined'>
                <NavButton section='squares-section'>Squares</NavButton>
                <NavButton section='lines-section'>Lines</NavButton>
                <NavButton section='category-section'>Category</NavButton>
                <NavButton section='pitch-section'>Pitch</NavButton>
            </ButtonGroup>
        </Stack>
    );
};

export default MeasurementFormNavigation;
