import React, { ReactNode, useEffect, useRef, useState } from 'react';
import MeasurementDialog from './MeasurementDialog';
import { useMediaQuery, useTheme } from '@mui/material';
import MeasurementFormNavigation from './MeasurementFormNavigation';
import MeasurementForm from './MeasurementForm';
import { ProjectDrawing, Measurements } from 'lib/Models/ProjectDrawing';
import {
    getProjectDrawingById,
    updateProjectDrawing,
} from 'actions/ProjectDrawingActions';
import { useDispatch } from 'react-redux';
import { setReduxMessage } from 'actions/ReduxMessagesActions';

type MeasurementFormDialogProps = {
    projectName: string;
    projectId: string;
    projectDrawing: ProjectDrawing;
    open: boolean;
    onClose?: () => void;
    title: ReactNode;
    focusField?: keyof Measurements;
    saveMeasurements: () => void;
};

const MeasurementFormDialog = ({
    projectName,
    projectId,
    projectDrawing,
    open,
    onClose,
    title,
    focusField,
    saveMeasurements,
}: MeasurementFormDialogProps) => {
    const theme = useTheme();
    const shouldCollapseNav = useMediaQuery(theme.breakpoints.down(400));
    const dispatch = useDispatch();

    const [measurements, setMeasurements] = useState<Measurements>(
        projectDrawing.measurements
    );

    const measurementRef = useRef<Measurements>({
        ...projectDrawing.measurements,
    });

    const handleSaveMeasurementClick = async () => {
        const projectDrawingToUpdate = Object.assign({}, projectDrawing);
        projectDrawingToUpdate.measurements = { ...measurementRef.current };
        saveProjectDrawing(projectDrawingToUpdate, 'drawing');
        saveMeasurements(); //this is just to close dialog box
    };

    const saveProjectDrawing = async (
        drawing: ProjectDrawing,
        action: string
    ) => {
        const result: { response: any; error: any } = (await dispatch(
            updateProjectDrawing(projectId, drawing.version, drawing)
        )) as any;

        if (result.error) throw Error(result.error);
        if (action === 'drawing')
            dispatch(
                setReduxMessage('Measurements were successfully updated.')
            );
        dispatch(getProjectDrawingById(projectId));
    };

    const saveOverride = (val: boolean) => {
        const updatedDrawing = Object.assign({}, projectDrawing);
        updatedDrawing.measurements = Object.assign(
            projectDrawing.measurements,
            { overrideDrawingMeasurements: Boolean(val) }
        );
        saveProjectDrawing(updatedDrawing, 'override'); // save the overide to database
    };

    useEffect(() => {
        measurementRef.current = projectDrawing.measurements;
        setMeasurements(projectDrawing.measurements);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectDrawing.measurements, open]);

    return (
        <MeasurementDialog
            projectName={projectName}
            projectId={projectId}
            open={open}
            title={title}
            onClose={onClose}
            overrideDrawing={measurements!.overrideDrawingMeasurements}
            onOverrideSave={saveOverride}
            navigation={
                !shouldCollapseNav && (
                    <MeasurementFormNavigation focusField={focusField} />
                )
            }
            actions={[
                {
                    text: 'Cancel',
                    buttonVariant: 'md3-secondary',
                    onClick: onClose,
                },
                {
                    text: 'Save',
                    buttonVariant: 'md3-primary',
                    onClick: handleSaveMeasurementClick,
                },
            ]}
        >
            <MeasurementForm measurementRef={measurementRef} />
        </MeasurementDialog>
    );
};

export default MeasurementFormDialog;
