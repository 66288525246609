import React, { ChangeEvent, MutableRefObject, useState } from 'react';
import { Measurements } from 'lib/Models/ProjectDrawing';
import Squares from './FormSections/Squares';
import Lines from './FormSections/Lines';
import Pitch from './FormSections/Pitch';
import Category from './FormSections/Category';
import { SquaresMeasurements } from 'lib/Measurements';

type MeasurementFormProps = {
    measurementRef: MutableRefObject<Measurements>;
};

function calculateWastePercentage(actualSquares = 0, totalSquares = 0) {
    return ((totalSquares - actualSquares) * 100) / actualSquares;
}

function calculateTotalSquares(actualSquares = 0, wastePercentage = 0) {
    return actualSquares + actualSquares * (wastePercentage / 100);
}

const MeasurementForm = ({ measurementRef }: MeasurementFormProps) => {
    const [reRenderWastePercentage, setReRenderWastePercentage] = useState(0);
    const [reRenderTotalSquares, setReRenderTotalSquares] = useState(0);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value
            ? parseFloat(event.target.value)
            : 0;

        const propertyToUpdate = event.target.id as keyof Measurements;

        const updatedMeasurements = {
            ...measurementRef.current,
            [propertyToUpdate]: newValue,
        };

        if (
            propertyToUpdate === SquaresMeasurements.ActualSquares ||
            propertyToUpdate === SquaresMeasurements.WastePercentage
        ) {
            updatedMeasurements.totalSquares = calculateTotalSquares(
                updatedMeasurements.actualSquares,
                updatedMeasurements.wastePercentage
            );
            setReRenderTotalSquares(Math.random());
        } else if (propertyToUpdate === SquaresMeasurements.TotalSquares) {
            updatedMeasurements.wastePercentage = calculateWastePercentage(
                updatedMeasurements.actualSquares,
                updatedMeasurements.totalSquares
            );
            setReRenderWastePercentage(Math.random());
        }
        measurementRef.current = updatedMeasurements;
    };

    return (
        <>
            <Squares
                key='squares'
                reRenderTotalSquares={reRenderTotalSquares}
                reRenderWastePercentage={reRenderWastePercentage}
                squaresMeasurements={measurementRef.current}
                handleChange={handleChange}
            ></Squares>
            <Lines
                linesMeasurements={measurementRef.current}
                handleChange={handleChange}
            ></Lines>
            <Category
                categoryMeasurements={measurementRef.current}
                handleChange={handleChange}
            ></Category>
            <Pitch
                pitchMeasurements={measurementRef.current}
                handleChange={handleChange}
            ></Pitch>
        </>
    );
};

export default MeasurementForm;
