import { Box, Button, Stack } from '@mui/material';
import React, {ChangeEvent, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RoofSnapState } from 'lib/Models';
import { getProjectEstimates } from 'actions/ProjectEstimates';
import EstimateCTA from './EstimateCTA';
import SectionTitle from '../SectionTitle';
import EstimatesListItem from './EstimatesListItem';
import CreateEstimateDialog from 'components/CreateEstimate/CreateEstimateDialog';
import RenameEstimateDialog from 'components/RenameEstimateDialog/RenameEstimateDialog';
import { createProjectEstimate, deleteProjectEstimate, updateProjectEstimate } from 'actions/ProjectEstimate';
import LoggerWrapper from 'lib/Logger';
import { useHistory } from 'react-router';
import AddIcon from '@mui/icons-material/Add';
import { Estimate } from 'lib/Models/Estimate';
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";

type EstimatesListSectionProps = {
    projectId: string;
};

type EstimateData = {
    name: string;
    templateId: string;
};

type UpdatedEstimate = {
    estimateToUpdate: Estimate;
    estimateId: string;
    estimateName: string;
    renameEstimateModalOpen: boolean;
    deleteEstimateModalOpen: boolean;
}

const EstimatesListSection = ({ projectId }: EstimatesListSectionProps) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        projectEstimates: { data: estimates },
    } = useSelector((state: RoofSnapState) => state);

    const [createEstimateDialogOpen, setCreateEstimateDialogOpen] =
        useState(false);

    const [updatedEstimate, setUpdatedEstimate] = useState<UpdatedEstimate>({
        estimateId: '',
        estimateName: '',
        estimateToUpdate: {} as Estimate,
        renameEstimateModalOpen: false,
        deleteEstimateModalOpen: false,
    });

    useEffect(() => {
        dispatch(getProjectEstimates(projectId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    const useCTA = !Boolean(estimates?.length);

    const handleCreateEstimateClicked = async (estimateData: EstimateData) => {
        setCreateEstimateDialogOpen(false);
        try {
            let result: any = await dispatch(
                createProjectEstimate(projectId, estimateData)
            );
            if (result.response.id) {
                history.push(
                    `/projects/${projectId}/estimates/${result.response.id}`
                );
            }
        } catch (error) {
            LoggerWrapper.log(error);
        }
    };

    const handleCancelCreatingEstimate = () => {
        setCreateEstimateDialogOpen(false);
    };

    const AddEstimateButton = () => (
        <Box sx={{ pr: '12px' }} alignSelf='flex-end'>
            <Button
                onClick={() => setCreateEstimateDialogOpen(true)}
                variant='text'
                sx={{ textTransform: 'none' }}
            >
                <AddIcon sx={{ paddingRight: '8px' }} /> Add
            </Button>
        </Box>
    );

    const handleEditEstimate = (estimate: Estimate) => {
        history.push(`/projects/${projectId}/estimates/${estimate.id}`);
    };

    const openRenameEstimateDialog = (estimate: Estimate) => {
        setUpdatedEstimate({
            estimateId: estimate.id,
            estimateName: estimate.name,
            estimateToUpdate: estimate,
            renameEstimateModalOpen: true,
            deleteEstimateModalOpen: false,
        });
    };

    const handleRenameEstimateChange = (event: ChangeEvent<HTMLInputElement>) => {
        setUpdatedEstimate({
            ...updatedEstimate,
            estimateToUpdate: {
                ...updatedEstimate.estimateToUpdate,
                name: event.target.value
            }
        });
    };

    const handleRenameEstimate = async () => {
        try {
            await dispatch(updateProjectEstimate(projectId, updatedEstimate.estimateId, updatedEstimate.estimateToUpdate.version, updatedEstimate.estimateToUpdate));
            clearUpdatedEstimate();
            dispatch(getProjectEstimates(projectId));
        } catch (error) {
            LoggerWrapper.log(error);
        }
    };

    const openDeleteEstimateDialog = (estimate: Estimate) => {
        setUpdatedEstimate({
            estimateId: estimate.id,
            estimateName: estimate.name,
            estimateToUpdate: {} as Estimate,
            renameEstimateModalOpen: false,
            deleteEstimateModalOpen: true,
        });
    };

    const handleDeleteEstimate = async () => {
        try {
            await dispatch(deleteProjectEstimate(projectId, updatedEstimate.estimateId));
            clearUpdatedEstimate();
            dispatch(getProjectEstimates(projectId));
        } catch (error) {
            LoggerWrapper.log(error);
        }
    }

    const clearUpdatedEstimate = () => {
        setUpdatedEstimate({
            estimateId: '',
            estimateName: '',
            estimateToUpdate: {} as Estimate,
            renameEstimateModalOpen: false,
            deleteEstimateModalOpen: false,
        });
    }

    return (
        <Box mt='30px'>
            <Box mb={1.5}>
                <SectionTitle title='Estimates' />
            </Box>
            {useCTA && (
                <EstimateCTA
                    onTryEstimate={() => setCreateEstimateDialogOpen(true)}
                />
            )}
            {!useCTA && (
                <Stack gap='0.625rem'>
                    {estimates?.map((estimate) => (
                        <EstimatesListItem
                            onEditEstimate={handleEditEstimate}
                            onRenameEstimate={openRenameEstimateDialog}
                            onDeleteEstimate={openDeleteEstimateDialog}
                            estimate={estimate} />
                    ))}
                    <AddEstimateButton />
                </Stack>
            )}
            <CreateEstimateDialog
                open={createEstimateDialogOpen}
                onCreateClick={handleCreateEstimateClicked}
                onCancelClick={handleCancelCreatingEstimate}
            />
            <RenameEstimateDialog
                estimate={updatedEstimate.estimateToUpdate}
                open={updatedEstimate.renameEstimateModalOpen}
                onChange={handleRenameEstimateChange}
                onSaveClick={handleRenameEstimate}
                onCancelClick={clearUpdatedEstimate}
            />
            <ConfirmationModal
                title={`Delete ${updatedEstimate.estimateName}`}
                description="Are you sure you want to remove this estimate?"
                handleConfirm={handleDeleteEstimate}
                confirmText="Delete"
                isOpen={updatedEstimate.deleteEstimateModalOpen}
                handleClose={clearUpdatedEstimate}
            />
        </Box>
    );
};

export default EstimatesListSection;
