import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Box, Grid } from '@mui/material';
import { getProjectById, updateProjectDetails } from 'actions/ProjectActions';
import { RoofSnapState, Project } from 'lib/Models';
import NotesDialog, { NotesData } from './Components/NotesDialog';
import ProjectDetails from './Components/ProjectDetails';
import MeasurementSection from './Components/MeasurementSection';
import { ProjectImagesSection } from './Components/ProjectImages';
import ProjectTitleBar from './Components/ProjectTitleBar';
import ProjectStatus from 'lib/Models/ProjectStatus';
import EstimatesListSection from './Components/Estimates/EstimatesListSection';
import { PaymentSection } from './Components/PaymentSection';
import featureFlags from '../../lib/FeatureFlags';
import { getProjectDrawingById } from 'actions/ProjectDrawingActions';

type ProjectParams = {
    projectId: string;
};

export const ProjectHome: React.FC = () => {
    const { projectId } = useParams<ProjectParams>();
    const dispatch = useDispatch();

    const {
        project: { currentProject },
        organizationFeatures: { enableEverProPayments },
    } = useSelector((store: RoofSnapState) => store);

    const { projectDrawing } = useSelector(
        (state: RoofSnapState) => state.projectDrawing
    );

    const [openNotesDialog, setOpenNotesDialog] = React.useState(false);

    const handleNotesClick = () => {
        setOpenNotesDialog(true);
    };
    const handleSaveNotes = (notes: NotesData) => {
        let updatedProject = { ...currentProject, ...notes };
        dispatch(updateProjectDetails(updatedProject));
    };
    const handleStatusChange = (status: keyof ProjectStatus) => {
        let updatedProject = { ...currentProject, projectStatus: status };
        dispatch(updateProjectDetails(updatedProject));
    };

    const isEmptyProject = (project: Project) => {
        return Object.keys(project).length === 0;
    };
    React.useEffect(() => {
        if (!currentProject || isEmptyProject(currentProject)) {
            dispatch(getProjectById(projectId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    React.useEffect(() => {
        dispatch(getProjectDrawingById(projectId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    return (
        <>
            <Grid container justifyContent='center'>
                <Grid item xs={12} sm={10} lg={8}>
                    {!isEmptyProject(currentProject) && (
                        <>
                            <Box mt={2}>
                                <ProjectTitleBar
                                    title={currentProject.projectName}
                                    status={currentProject.projectStatus}
                                    onStatusChange={(status) => {
                                        handleStatusChange(
                                            status as keyof ProjectStatus
                                        );
                                    }}
                                ></ProjectTitleBar>
                            </Box>
                            <Box marginTop={'18px'}>
                                <ProjectDetails
                                    project={currentProject}
                                    addNotesClicked={handleNotesClick}
                                ></ProjectDetails>
                            </Box>
                            <MeasurementSection
                                projectDrawing={projectDrawing}
                                project={currentProject}
                            />
                            <EstimatesListSection projectId={projectId} />
                            {enableEverProPayments &&
                                featureFlags.everProPayments && (
                                    <Box mt='30px'>
                                        <PaymentSection />
                                    </Box>
                                )}
                            <ProjectImagesSection projectId={projectId} />
                        </>
                    )}
                </Grid>
            </Grid>
            <NotesDialog
                open={openNotesDialog}
                setOpen={setOpenNotesDialog}
                saveNotes={handleSaveNotes}
                notes={currentProject.notes}
                notesContract={currentProject.notesContract}
                notesDelivery={currentProject.notesDelivery}
                notesInstallation={currentProject.notesInstallation}
                notesPreStart={currentProject.notesPreStart}
            ></NotesDialog>
        </>
    );
};

export default ProjectHome;
