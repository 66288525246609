import React, { ChangeEvent } from 'react';
import MeasurementsInput from './MeasurementsInput';
import { Typography } from '@ui';
import Section from './Section';
import { Measurements } from 'lib/Models/ProjectDrawing';

type SquaresProps = {
    squaresMeasurements: Partial<Measurements>;
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    reRenderWastePercentage?: number;
    reRenderTotalSquares?: number;
};

const Squares = ({
    squaresMeasurements,
    reRenderWastePercentage,
    reRenderTotalSquares,
    handleChange,
}: SquaresProps) => {
    return (
        <Section id='squares-section'>
            <Typography variant='subtitle2'>Squares</Typography>
            <MeasurementsInput
                id='actualSquares'
                label='Actual'
                subLabel='*excludes low slope'
                unit='Sq.'
                measurementValue={squaresMeasurements.actualSquares || 0}
                handleChange={handleChange}
                disabled={!squaresMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='wastePercentage'
                key={`wastePercentage${reRenderWastePercentage}`}
                label='Waste Percentage'
                unit='%'
                measurementValue={squaresMeasurements.wastePercentage || 0}
                handleChange={handleChange}
                disabled={false}
            />
            <MeasurementsInput
                id='totalSquares'
                key={`totalSquares${reRenderTotalSquares}`}
                label='Total'
                measurementValue={squaresMeasurements.totalSquares || 0}
                unit='Sq.'
                subLabel='*excludes low slope'
                handleChange={handleChange}
                disabled={!squaresMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='lowSlope'
                label='Low Slope'
                measurementValue={squaresMeasurements.lowSlope || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!squaresMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='twoStory'
                label='Two-Story'
                measurementValue={squaresMeasurements.twoStory || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!squaresMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='secondLayer'
                label='Second-Layer'
                measurementValue={squaresMeasurements.secondLayer || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!squaresMeasurements.overrideDrawingMeasurements}
            />
        </Section>
    );
};

export default Squares;
