import {
    GET_CURRENT_USER_REQUEST,
    GET_CURRENT_USER_SUCCESS,
    GET_CURRENT_USER_FAILURE,
    GET_CURRENT_USER_REQUEST_BACKGROUND,
    GET_CURRENT_USER_SUCCESS_BACKGROUND,
} from '../actions/User';
import LocalStorageWrapper from '../lib/LocalStorageWrapper';
import decode from '../lib/decodeRoofSnapAuthToken';

const accessToken = LocalStorageWrapper.getItem('accessToken');
const { userId } = decode(accessToken);

const defaultState = {
    userId,
    userName: null,
    firstName: null,
    lastName: null,
    isFetching: false,
    active: null,
    allowedToEditEstimateItems: false,
    chameleonEncryptedUserId: null,
    nearmapApiKey: null,
    useNearmapApiKey: false,
    organizationId: null,
    allowedRoofSnapPlatforms: null,
    userRoleIds: [],
    contactNumber: null,
    edgeStatus: null,
};

function currentUser(state = defaultState, action) {
    switch (action.type) {
        case GET_CURRENT_USER_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case GET_CURRENT_USER_SUCCESS:
        case GET_CURRENT_USER_SUCCESS_BACKGROUND:
            return Object.assign({}, state, {
                userId: action.response.userId,
                chameleonEncryptedUserId:
                    action.response.chameleonEncryptedUserId,
                userName: action.response.userName,
                firstName: action.response.firstName,
                lastName: action.response.lastName,
                nearmapApiKey: action.response.nearmapApiKey,
                useNearmapApiKey: !!action.response.nearmapApiKey,
                organizationId: action.response.organizationId,
                allowedToEditEstimateItems:
                    action.response.allowedToEditEstimateItems,
                active: action.response.active,
                allowedRoofSnapPlatforms:
                    action.response.allowedRoofSnapPlatforms,
                isFetching: false,
                userRoleIds: action.response.userRoleIds,
                contactNumber: action.response.contactNumber,
                edgeStatus: action.response.edgeStatus,
            });
        case GET_CURRENT_USER_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
            });
        case GET_CURRENT_USER_REQUEST_BACKGROUND:
            return Object.assign({}, state, {
                isFetching: false,
            });
        default:
            return state;
    }
}

export default currentUser;
