import { getOrganization } from 'actions/Organization';
import {
    getOrganizationOpenSketchOrders,
    getOrganizationsFreeSketchOrdersBalance,
} from 'actions/OrganizationFreeSketchOrdersActions';
import LocalStorageWrapper from 'lib/LocalStorageWrapper';
import { RoofSnapState } from 'lib/Models';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import WelcomeToRoofSnap from './WelcomeToRoofSnap';
import SignUpError from './SignUpError';
import WelcomeSketchOS from './WelcomeSketchOS';
import { styled, useTheme } from '@mui/material';
import smoothscroll from 'smoothscroll-polyfill';

import Organization from 'lib/Models/Organization';
import User from 'lib/Models/User';
import OrganizationFeatures from 'lib/Models/OrganizationFeatures';
import buildProfile from 'lib/BuildProfile';

const chameleon = require('@chamaeleonidae/chmln');

const RootDiv = styled('div')(({ theme }) => ({
    height: '100vh',
    position: 'fixed',
    overflow: 'scroll',
    top: 0,
    backgroundColor: '#ffffff',
    zIndex: theme.zIndex.appBar + 1,
    width: '100%',
    margin: 'auto',
}));

// kick off the polyfill. This ensures that the smooth scrolling works on all browsers
smoothscroll.polyfill();

const Onboarding = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();

    const [renderWelcome, setRenderWelcome] = useState(true);
    const [showSketchOs, setShowSketchOs] = useState(false);
    const [showError, setShowError] = useState(false);

    const {
        currentUser,
        organization,
        organizationFeatures,
        signUp,
        organizationFreeSketchOrders: {
            freeSketchOrderBalance,
            openSketchOrders,
        },
    } = useSelector((state: RoofSnapState) => state);

    const getFreeSketchOrders = () => {
        dispatch(getOrganizationsFreeSketchOrdersBalance(organization.id));
        dispatch(getOrganizationOpenSketchOrders(organization.id));
    };

    const hasAvailableFreeSketchOrders = () => {
        return freeSketchOrderBalance - openSketchOrders > 0;
    };

    const reportToChameleon = (
        user: any,
        org: Organization,
        orgFeatures: any
    ) => {
        const chameleonProfile = buildChameleonProfile(user, org, orgFeatures);
        chameleon.init(process.env.REACT_APP_CHAMELEON_TRACKING_ID, {
            fastUrl: 'https://fast.chameleon.io/',
        });
        chameleon.identify(user.userName, chameleonProfile);
    };

    const buildChameleonProfile = (
        user: User,
        org: Organization,
        orgFeatures: OrganizationFeatures
    ) => ({
        ...buildProfile(user, org, orgFeatures, true),
        userRoleIds: user.userRoleIds,
    });

    const handleDismissSlidesClick = () => {
        setRenderWelcome(false);
        const continueToSketchos = hasAvailableFreeSketchOrders();

        if (signUp.error) {
            setShowError(true);
            setRenderWelcome(false);
            return;
        }

        // If we are no longer signing up and this is not a new user, direct them to their email.
        if (!signUp.isNewUser) {
            history.push('/username-already-registered');
            return;
        }

        if (continueToSketchos) {
            setShowSketchOs(true);
            return;
        }

        history.push('/projects');
    };

    useEffect(() => {
        if (!signUp.isSigningUp && LocalStorageWrapper.getItem('accessToken')) {
            dispatch(getOrganization());
            getFreeSketchOrders();
            reportToChameleon(currentUser, organization, organizationFeatures);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signUp.isSigningUp]);

    return (
        <RootDiv theme={theme}>
            {renderWelcome && (
                <WelcomeToRoofSnap
                    onDismissClick={handleDismissSlidesClick}
                    isLoading={signUp.isSigningUp}
                />
            )}

            {showError && <SignUpError />}
            {showSketchOs && <WelcomeSketchOS />}
        </RootDiv>
    );
};

export default Onboarding;
