import React, { ChangeEvent } from 'react';
import { Typography } from '@ui';
import Section from './Section';
import MeasurementsInput from './MeasurementsInput';
import { Measurements } from 'lib/Models/ProjectDrawing';

type CategoryProps = {
    categoryMeasurements: Partial<Measurements>;
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const Category = ({ categoryMeasurements, handleChange }: CategoryProps) => {
    return (
        <Section id='category-section'>
            <Typography variant='subtitle2'>Category</Typography>
            <MeasurementsInput
                id='iceWaterShield'
                label='Ice Watershield'
                measurementValue={categoryMeasurements.iceWaterShield || 0}
                unit='Ft.'
                handleChange={handleChange}
                disabled={!categoryMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='ridgeVent'
                label='Ridge Vent'
                measurementValue={categoryMeasurements.ridgeVent || 0}
                unit='Ft.'
                handleChange={handleChange}
                disabled={!categoryMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='rakeEdge'
                label='Rake Edge'
                measurementValue={categoryMeasurements.rakeEdge || 0}
                unit='Ft.'
                handleChange={handleChange}
                disabled={!categoryMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='eaveEdge'
                label='Eave Edge'
                measurementValue={categoryMeasurements.eaveEdge || 0}
                unit='Ft.'
                handleChange={handleChange}
                disabled={!categoryMeasurements.overrideDrawingMeasurements}
            />{' '}
            <MeasurementsInput
                id='stepFlashing'
                label='Step Flashing'
                measurementValue={categoryMeasurements.stepFlashing || 0}
                unit='Ft.'
                handleChange={handleChange}
                disabled={!categoryMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='apronFlashing'
                label='Apron Flashing'
                measurementValue={categoryMeasurements.apronFlashing || 0}
                unit='Ft.'
                handleChange={handleChange}
                disabled={!categoryMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='gutters'
                label='Gutters'
                measurementValue={categoryMeasurements.gutters || 0}
                unit='Ft.'
                handleChange={handleChange}
                disabled={!categoryMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='gutterToppers'
                label='Gutter Toppers'
                measurementValue={categoryMeasurements.gutterToppers || 0}
                unit='Ft.'
                handleChange={handleChange}
                disabled={!categoryMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='downSpouts'
                label='Down Spouts'
                measurementValue={categoryMeasurements.downSpouts || 0}
                unit='Ft.'
                handleChange={handleChange}
                disabled={false}
            />
        </Section>
    );
};

export default Category;
