import React, { ChangeEvent } from 'react';
import { Typography } from '@ui';
import Section from './Section';
import MeasurementsInput from './MeasurementsInput';
import { Measurements } from 'lib/Models/ProjectDrawing';

type LinesProps = {
    linesMeasurements: Partial<Measurements>;
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const Lines = ({ linesMeasurements, handleChange }: LinesProps) => {
    return (
        <Section id='lines-section'>
            <Typography variant='subtitle2'>Lines</Typography>
            <MeasurementsInput
                id='eaves'
                label='Eaves'
                measurementValue={linesMeasurements.eaves || 0}
                unit='Ft.'
                handleChange={handleChange}
                disabled={!linesMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='rakes'
                label='Rakes'
                measurementValue={linesMeasurements.rakes || 0}
                unit='Ft.'
                handleChange={handleChange}
                disabled={!linesMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='ridges'
                label='Ridges'
                measurementValue={linesMeasurements.ridges || 0}
                unit='Ft.'
                handleChange={handleChange}
                disabled={!linesMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='hips'
                label='Hips'
                measurementValue={linesMeasurements.hips || 0}
                unit='Ft.'
                handleChange={handleChange}
                disabled={!linesMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='valleys'
                label='Valleys'
                measurementValue={linesMeasurements.valleys || 0}
                unit='Ft.'
                handleChange={handleChange}
                disabled={!linesMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='step'
                label='Step'
                measurementValue={linesMeasurements.step || 0}
                unit='Ft.'
                handleChange={handleChange}
                disabled={!linesMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='wall'
                label='Wall'
                measurementValue={linesMeasurements.wall || 0}
                unit='Ft.'
                handleChange={handleChange}
                disabled={!linesMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitchChange'
                label='Pitch Change'
                measurementValue={linesMeasurements.pitchChange || 0}
                unit='Ft.'
                handleChange={handleChange}
                disabled={!linesMeasurements.overrideDrawingMeasurements}
            />
        </Section>
    );
};

export default Lines;
