import React, { PropsWithChildren, ReactNode, useState } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    IconButton,
    Stack,
} from '@mui/material';
import { DownloadProjectCsvIconButton } from '@roofsnap';
import { Button, Dialog, Typography } from '@ui';
import { CloseIcon } from '@ui/Icons';

import { CustomButtonVariants } from '@ui/Button';
import { SwitchButton } from '@ui/SwitchButton';
import { Box } from '@material-ui/core';
import ConfirmDialog from './ConfirmDialog';

type MeasurementDialogProps = PropsWithChildren<{
    projectName: string;
    projectId: string;
    overrideDrawing: boolean;
    open: boolean;
    onClose?: () => void;
    onOverrideSave: (val: boolean) => void;
    title: ReactNode;
    navigation?: ReactNode;
    actions: {
        text: string;
        buttonVariant: CustomButtonVariants;
        onClick?: () => void;
    }[];
}>;

const MeasurementDialog = ({
    projectName,
    projectId,
    overrideDrawing,
    open,
    onClose,
    onOverrideSave,
    title,
    navigation,
    children,
    actions,
}: MeasurementDialogProps) => {
    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
    return (
        <>
            <Dialog open={open}>
                <DialogTitle>
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems={'center'}
                    >
                        <Stack direction='row' alignItems='center'>
                            {title}
                        </Stack>
                        <Stack direction='row' alignItems='center'>
                            {projectName && projectId && (
                                <DownloadProjectCsvIconButton
                                    projectName={projectName}
                                    projectId={projectId}
                                />
                            )}
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    </Stack>
                </DialogTitle>
                <Divider />
                {navigation}
                <DialogContent>{children}</DialogContent>
                <Divider />
                <DialogActions>
                    <Box
                        display={'flex'}
                        flexGrow={1}
                        justifyContent='flex-start'
                        style={{ paddingLeft: '10px' }}
                    >
                        <FormControlLabel
                            control={
                                <SwitchButton
                                    checked={overrideDrawing}
                                    onChange={(e: any) => {
                                        setConfirmDialogOpen(true);
                                    }}
                                ></SwitchButton>
                            }
                            label={
                                <Typography style={{ paddingLeft: '10px' }}>
                                    Override
                                </Typography>
                            }
                        />
                    </Box>

                    {actions.map((action, index) => (
                        <Button
                            key={index}
                            customVariant={action.buttonVariant}
                            onClick={action.onClick}
                        >
                            {action.text}
                        </Button>
                    ))}
                </DialogActions>
            </Dialog>
            {confirmDialogOpen && (
                <ConfirmDialog
                    open={confirmDialogOpen}
                    actions={[
                        {
                            text: 'Cancel',
                            buttonVariant: 'md3-secondary',
                            onClick: () => {
                                setConfirmDialogOpen(false);
                            },
                        },
                        {
                            text: 'Confirm',
                            buttonVariant: 'md3-confirm',
                            onClick: () => {
                                setConfirmDialogOpen(false);
                                onOverrideSave(!overrideDrawing);
                            },
                        },
                    ]}
                >
                    <Box style={{ maxWidth: '327px' }}>
                        {!overrideDrawing ? (
                            <Typography variant='body-medium'>
                                Are you sure you want to override measurements?
                                Once you break this relationship, any changes
                                you make to your diagram drawing will no longer
                                update here.
                            </Typography>
                        ) : (
                            <Typography variant='body-medium'>
                                This will update your measurements based on the
                                current sketch.
                            </Typography>
                        )}
                    </Box>
                </ConfirmDialog>
            )}
        </>
    );
};

export default MeasurementDialog;
