import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Dialog, Typography } from '@ui';
import Button, { CustomButtonVariants } from '@ui/Button';
import React, { PropsWithChildren } from 'react';

type ConfirmDialogProps = PropsWithChildren<{
    open: boolean;
    actions: {
        text: string;
        buttonVariant: CustomButtonVariants;
        onClick?: any;
    }[];
}>;

const ConfirmDialog = ({ open, actions, children }: ConfirmDialogProps) => {
    return (
        <Dialog open={open}>
            <DialogTitle>
                <Typography variant='title-large'>
                    Override Measurements
                </Typography>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                {actions.map((action, index) => (
                    <Button
                        key={index}
                        customVariant={action.buttonVariant}
                        onClick={action.onClick}
                    >
                        {action.text}
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
