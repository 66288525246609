import React, { ChangeEvent } from 'react';
import { Typography } from '@ui';
import Section from './Section';
import MeasurementsInput from './MeasurementsInput';
import { Measurements } from 'lib/Models/ProjectDrawing';

type PitchProps = {
    pitchMeasurements: Partial<Measurements>;
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const Pitch = ({ pitchMeasurements, handleChange }: PitchProps) => {
    return (
        <Section id='pitch-section'>
            <Typography variant='subtitle2'>Pitch</Typography>
            <MeasurementsInput
                id='pitch0In12'
                label='0 in 12'
                measurementValue={pitchMeasurements.pitch0In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch1In12'
                label='1 in 12'
                measurementValue={pitchMeasurements.pitch1In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch2In12'
                label='2 in 12'
                measurementValue={pitchMeasurements.pitch2In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch3In12'
                label='3 in 12'
                measurementValue={pitchMeasurements.pitch3In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch4In12'
                label='4 in 12'
                measurementValue={pitchMeasurements.pitch4In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch5In12'
                label='5 in 12'
                measurementValue={pitchMeasurements.pitch5In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch6In12'
                label='6 in 12'
                measurementValue={pitchMeasurements.pitch6In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch7In12'
                label='7 in 12'
                measurementValue={pitchMeasurements.pitch7In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch8In12'
                label='8 in 12'
                measurementValue={pitchMeasurements.pitch8In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch9In12'
                label='9 in 12'
                measurementValue={pitchMeasurements.pitch9In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch10In12'
                label='10 in 12'
                measurementValue={pitchMeasurements.pitch10In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch11In12'
                label='11 in 12'
                measurementValue={pitchMeasurements.pitch11In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch12In12'
                label='12 in 12'
                measurementValue={pitchMeasurements.pitch12In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch13In12'
                label='13 in 12'
                measurementValue={pitchMeasurements.pitch13In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch14In12'
                label='14 in 12'
                measurementValue={pitchMeasurements.pitch14In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch15In12'
                label='15 in 12'
                measurementValue={pitchMeasurements.pitch15In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch16In12'
                label='16 in 12'
                measurementValue={pitchMeasurements.pitch16In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch17In12'
                label='17 in 12'
                measurementValue={pitchMeasurements.pitch17In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch18In12'
                label='18 in 12'
                measurementValue={pitchMeasurements.pitch18In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch19In12'
                label='19 in 12'
                measurementValue={pitchMeasurements.pitch19In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch20In12'
                label='20 in 12'
                measurementValue={pitchMeasurements.pitch20In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch21In12'
                label='21 in 12'
                measurementValue={pitchMeasurements.pitch21In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch22In12'
                label='22 in 12'
                measurementValue={pitchMeasurements.pitch22In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch23In12'
                label='23 in 12'
                measurementValue={pitchMeasurements.pitch23In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
            <MeasurementsInput
                id='pitch24In12'
                label='24 in 12'
                measurementValue={pitchMeasurements.pitch24In12 || 0}
                unit='Sq.'
                handleChange={handleChange}
                disabled={!pitchMeasurements.overrideDrawingMeasurements}
            />
        </Section>
    );
};

export default Pitch;
