import AnalyticsProfile from './Models/AnalyticsProfile';
import Organization from './Models/Organization';
import OrganizationFeatures from './Models/OrganizationFeatures';
import User from './Models/User';

const buildProfile = (
    user: User,
    org: Organization,
    orgFeatures: OrganizationFeatures,
    isChameleon?: boolean
) => {
    const isAdmin = user.userRoleIds?.includes(2);
    const profile: AnalyticsProfile = {
        userId: isChameleon ? user.chameleonEncryptedUserId : user.userId,
        userName: user.userName,
        firstName: user.firstName,
        lastName: user.lastName,
        allowedToEditEstimateItems: isAdmin || user.allowedToEditEstimateItems,
        orgId: org.id,
        orgName: org.name,
        subscriptionExpires: org.subscriptionExpires,
        subscriptionExpired: org.subscriptionExpired,
        sketchServiceEnabled: org.sketchServiceEnabled,
        addOnsDisabled: org.addOnsDisabled,
        status: org.status,
        subscriptionId: org.subscriptionId,
        orgEstimatingEnabled: orgFeatures.estimating,
        isAdmin,
        isSalesAppOnly: user.userRoleIds?.includes(3),
    };
    return profile;
};

export default buildProfile;
